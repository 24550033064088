<template>
  <v-card min-height="800px">
    <v-card-title class="justify-center">Yabancı Dil Uygulamaları Giriş Bilgileri</v-card-title>
    <v-container>
      <v-list v-if="!loading && editedItem != null && Object.keys(editedItem).length !== 0">
        <v-list-item-content v-if="editedItem.PearsonUserName != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Pearson Kullanıcı Adı</strong>
            <v-chip class="ml-3" label small outlined color="primary">{{ editedItem.PearsonUserName }}</v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.PearsonPassword != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Pearson Şifre</strong>
            <v-chip class="ml-3" label small outlined :color="showPasswords[0] ? 'success' : 'error'" @click="showPasswords[0] = !showPasswords[0], $forceUpdate();">
              {{ showPasswords[0] ? editedItem.PearsonPassword : 'Şifrenizi görmek için tıklayınız' }}
            </v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.BurlingtonUserName != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>BurlingtonEnglish Kullanıcı Adı</strong>
            <v-chip class="ml-3" label small outlined color="primary">{{ editedItem.BurlingtonUserName }}</v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.BurlingtonPassword != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>BurlingtonEnglish Şifre</strong>
            <v-chip class="ml-3" label small outlined :color="showPasswords[1] ? 'success' : 'error'" @click="showPasswords[1] = !showPasswords[1], $forceUpdate();">
              {{ showPasswords[1] ? editedItem.BurlingtonPassword : 'Şifrenizi görmek için tıklayınız' }}
            </v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ActiveLearnUserName != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>ActiveLearn Kullanıcı Adı</strong>
            <v-chip class="ml-3" label small outlined color="primary"> {{ editedItem.ActiveLearnUserName }}</v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ActiveLearnPassword != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>ActiveLearn Şifre</strong>
            <v-chip class="ml-3" label small outlined :color="showPasswords[2] ? 'success' : 'error'" @click="showPasswords[2] = !showPasswords[2], $forceUpdate();">
              {{ showPasswords[2] ? editedItem.ActiveLearnPassword : 'Şifrenizi görmek için tıklayınız' }}
            </v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ActiveLearnSchoolCode != null">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>ActiveLearn Okul Kodu</strong>
            <v-chip class="ml-3" label small outlined color="primary">{{ editedItem.ActiveLearnSchoolCode }}</v-chip>
            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
          </v-list-item-title>
        </v-list-item-content>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'diger-bilgiler',
  data() {
    return {
      editedItem: {},
      showPasswords: [false, false, false],
      loading: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Student/extras', {
        sortBy: ["Id"],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
      })
          .then((data) => {
            this.editedItem = data.data.Results[0]
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>
